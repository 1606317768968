<!--
 * @Description: 文件描述
-->
<template>
  <div class="app_auth_wrapper">
    <a-radio-group v-model="value" :diabled="!role.id" @change="handleToggleAuth">
      <a-radio :value="1">开启</a-radio>
      <a-radio :value="0">关闭</a-radio>
    </a-radio-group>
  </div>
</template>

<script>
import ApiController from '../mod/api'

export default {
  name: 'AppAuth',
  props: {
    role: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    role: {
      immediate: true,
      handler(newVal) {
        if (newVal.id) {
          this.fetchData()
        }
      }
    }
  },
  data() {
    return {
      value: undefined
    }
  },
  methods: {
    async fetchData() {
      const params = { id: this.role.id }
      const data = await ApiController.getDisableAppLogin(params)
      this.value = data
    },
    async handleToggleAuth() {
      const params = { id: this.role.id }
      await ApiController.disableAppLogin(params)
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.app_auth_wrapper {
  padding-left: 40px;
}
</style>
