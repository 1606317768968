import './index.less'
import { PageHeaderWrapper } from '@ant-design-vue/pro-layout/es/components'
import { Card, Modal, Menu, Icon, Button } from 'ant-design-vue'
import FormRoleEdit from './components/FormRoleEdit/index'
import FormCopyRole from './components/FormCopyRole/index'
import PermissionTree from '../components/PermissionTree'
import AppAuth from './components/AppAuth.vue'

export default {
  name: 'Role',
  created() {
    // 实例创建完成后被立即调用
    this.$loadStoreToData(this, 'RoleModule')
    this.$loadStoreToData(this, 'userManage')
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
      this.loadData()
    })
  },
  data() {
    return {
      enterItem: {},
      formBody: '',
      menus: [],
      openKeys: [],
      menuSelectKey: [],
      checkedPermissionIds: [],
      selectedItem: {},
      modalShow: false,
      dFormModalShow: false,
      loading: false,
      pagination: { size: 1000, page: 0 }
    }
  },
  computed: {
    authList() {
      return this.$store.state.permission?.btnAuthCodeList || []
    }
  },
  beforeDestroy() {
    this.$store.dispatch('fetchBtnAuthCodeList')
  },
  methods: {
    async loadData() {
      var res = await this.$store.dispatch('RoleModule/getRolesByPage', { pagination: this.pagination })
      if (!(this.menuSelectKey && this.menuSelectKey.length > 0)) {
        this.menuSelectKey = [res.content[0].id]
        this.selectedItem = res.content[0]
        if (this.selectedItem.authPermissionvms && this.selectedItem.authPermissionvms.length > 0) {
          this.checkedPermissionIds = this.selectedItem.authPermissionvms.map((i) => {
            return i.id
          })
        } else {
          this.checkedPermissionIds = []
        }
      }
      this.menus = this.renderMenu(res.content)
    },
    renderMenu(data) {
      return data.map((item) => {
        return (item.childVms && item.childVms.length > 0) ? this.renderSubMenu(item) : this.renderMenuItem(item)
      })
    },
    renderSubMenu(item) {
      this.openKeys.push(item.id)
      return <Menu.SubMenu title={
        <div><Icon type='folder' />{item.name}</div>} key={item.id}>
        {this.renderMenu(item.childVms)}
      </Menu.SubMenu>
    },
    renderMenuItem(item) {
      const hasEditAuth = this.authList.includes('EDIT_ROLE')
      const EditBtn = hasEditAuth ? <Icon type='form' onClick={this.handleBtnClick.bind(this, 'edit', item)} /> : null
      const DelBtn = hasEditAuth ? <Icon type="delete" onClick={this.handleBtnClick.bind(this, 'del', item)} /> : null
      return (
        <Menu.Item
          key={item.id}
          onClick={this.handelMenuItemClick.bind(this, item)}
          onMouseenter={() => {
            this.enterItem = item
            this.menus = this.renderMenu(this.rolesPage.data)
          }}>
          <div class='item'>
            <div>{item.name}</div>
            {this.enterItem && this.enterItem.id === item.id
              ? (
                  item.lockflg !== 'LOCK'
                    ? <div>
                      {EditBtn}
                      {DelBtn}
                    </div>
                : <Icon type='lock' />
              )
              : ''
            }
          </div>
        </Menu.Item>
      )
    },
    handelMenuItemClick(item) {
      this.selectedItem = item
      if (item.authPermissionvms && item.authPermissionvms.length > 0) {
        this.checkedPermissionIds = item.authPermissionvms.map((i) => {
          return i.id
        })
      } else {
        this.checkedPermissionIds = []
      }
    },
    menuItemSelect(item) {
      this.menuSelectKey = item.selectedKeys
      this.menus = this.renderMenu(this.rolesPage.data)
    },

    async handleBtnClick(todo, row) {
      this.selectedItem = row
      if (todo === 'create') {
        this.formBody = <FormRoleEdit
          formData={this.selectedItem}
          onSubmit={this.handleForm.bind(this, 'submit')}
          onCancel={this.handleForm.bind(this, 'cancel')} />
        this.modalShow = true
      } else if (todo === 'edit') {
        this.modalShow = true
        this.formBody = <FormRoleEdit
          formData={this.selectedItem}
          onSubmit={this.handleForm.bind(this, 'submit')}
          onCancel={this.handleForm.bind(this, 'cancel')} />
      } else if (todo === 'del') {
        await this.$store.dispatch('userManage/getAllAuthUsers', { size: 1000, page: 0, roles: [this.selectedItem.id] })
        var self = this
        this.$confirm({
          title: '删除确认',
          content: this.userPage.pagination.total === 0 ? '确定要删除该角色么？' : `有【${this.userPage.pagination.total}】名用户已关联了该角色，确定要删除该角色么？`,
          onOk: async () => {
            await self.$store.dispatch('RoleModule/delRole', self.selectedItem)
            await this.loadData()
          },
          onCancel() { }
        })
      } else if (todo === 'copy') {
        this.formBody = <FormCopyRole
          formData={this.selectedItem}
          onSubmit={this.handleForm.bind(this, 'copySubmit')}
          onCancel={this.handleForm.bind(this, 'cancel')} />
        this.modalShow = true
      } else if (todo === 'userGroup') {
        var routeData = this.$router.resolve({
          path: '/usercenter/backUserManage',
          query: { roleId: row.id }
        })
        window.open(routeData.href, '_blank')
      }
    },
    async handleForm(todo, formData, form) {
      var res
      if (todo === 'submit') {
        if (this.selectedItem && this.selectedItem.id) {
          res = await this.$store.dispatch('RoleModule/updateRole', { ...this.selectedItem, ...formData })
        } else {
          res = await this.$store.dispatch('RoleModule/createRole', { ...this.selectedItem, ...formData })
        }
      } else if (todo === 'copySubmit') {
        res = await this.$store.dispatch('RoleModule/copyRole', { ...formData })
        if (res.code) {
          res.data.roleName = res.data.name
        }
      } else if (todo === 'cancel') {
        this.modalShow = false
      }
      if (res.code) {
        this.setFormError(form, res)
      } else {
        form.resetFields()
        this.modalShow = false
        this.selectedItem = {}
        await this.loadData()
      }
    },
    setFormError(form, res) {
      var keys = Object.keys(res.data)
      var k = {}
      keys.forEach((item) => {
        k[item] = {
          value: form.getFieldValue(item),
          errors: [new Error(res.data[item])]
        }
      })
      form.setFields(k)
    },
    async handelTreeChange(checkedKeys) {
      try {
        this.loading = true
        var res = await this.$store.dispatch('RoleModule/allotPermissions', { id: this.selectedItem.id, pids: checkedKeys })
        if ((!res.code)) {
          await this.loadData()
          this.$message.success('角色权限更新成功！')
        } else {
          this.$message.error(res.msg)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  },

  render() {
    const hasEditAuth = this.authList.includes('EDIT_ROLE')
    const AddBtn = hasEditAuth ? (
      <Button
        onClick={this.handleBtnClick.bind(this, 'create', {})}
        style={{ marginRight: '10px' }}
        type='primary'>创建角色</Button>
    ) : null

    return (
      <PageHeaderWrapper
      >
        <div class='Role'>
          <Modal maskClosable={false} closable={false} footer={null} visible={this.modalShow}>
            {this.formBody || ''}
          </Modal>
          <Card bordered={false}>
            <div style={{ marginBottom: '10px' }}>
              { AddBtn }
            </div>
            <div class="body">
              <div class="menu">
                <Menu
                  selectedKeys={this.menuSelectKey}
                  mode={'inline'}
                  openKeys={this.openKeys}
                  onSelect={this.menuItemSelect.bind(this)}
                >
                  {this.menus}
                </Menu>
              </div>
              <div class="bodyContent">
              <a-spin spinning={this.loading}>
                <a-tabs type="card">
                  <a-tab-pane key="1" tab="菜单权限">
                    <PermissionTree
                      baseProps={{ disabled: this.selectedItem.lockflg === 'LOCK' }}
                      value={this.checkedPermissionIds}
                      onChange={this.handelTreeChange.bind(this)} />
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="App登录权限">
                    <AppAuth role={this.selectedItem}></AppAuth>
                  </a-tab-pane>
                </a-tabs>
              </a-spin>

              </div>
            </div>
          </Card>
        </div>
      </PageHeaderWrapper >
    )
  }
}
