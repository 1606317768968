var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app_auth_wrapper" },
    [
      _c(
        "a-radio-group",
        {
          attrs: { diabled: !_vm.role.id },
          on: { change: _vm.handleToggleAuth },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        [
          _c("a-radio", { attrs: { value: 1 } }, [_vm._v("开启")]),
          _c("a-radio", { attrs: { value: 0 } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }