import './index.less'
import { Tree } from 'ant-design-vue'
export default {
  name: 'PermissionTree',
  props: {
    // 默认选中key列表
    // defCheckedKeys: {
    //   type: Array,
    //   default: () => []
    // },
    baseProps: {
      type: Object,
      default: () => { }
    },
    value: {
      type: Array,
      default: () => []
    },
    // 默认禁用key列表
    defDisabledKeys: {
      type: Array,
      default: () => []
    }
  },
  created() {
    // 实例创建完成后被立即调用
    this.$loadStoreToData(this, 'CoreModule')
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
      console.info('PermissionTree mounted')
      this.loadData()
    })
  },
  data() {
    return {
      treeNodeList: [],
      expandeKeys: [],
      disabledKeys: [],
      disabledCheckedKeys: [],
      checkedKeys: [],
      subNodeCount: {}
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        if (newV && newV.length > 0) {
          this.checkedKeys = newV.map((item) => {
            return `permission-${item}`
          })
        } else {
          this.checkedKeys = []
        }
        this.treeNodeList = this.renderTree(this.allPermissionTree)
      }
    },
    defDisabledKeys: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.disabledKeys = newV || []
        if (newV && newV.length > 0) {
          this.disabledCheckedKeys = newV.map((item) => {
            return `permission-${item}`
          })
        } else {
          this.disabledCheckedKeys = []
        }
        this.treeNodeList = this.renderTree(this.allPermissionTree)
      }
    }
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('CoreModule/getAllPermissionTree')
      this.treeNodeList = this.renderTree(this.allPermissionTree)
    },
    renderTree(data) {
      if (data && data.length > 0) {
        return data.map((item) => {
          return (item.childVms && item.childVms.length > 0) ? this.renderSubTreeNode(item) : this.renderTreeNode(item)
        })
      } else {
        return []
      }
    },
    getCount(item) {
      var count = 0
      if (item.permissionVMS && item.permissionVMS.length > 0) {
        count = count + item.permissionVMS.length
      }
      if (item.childVms && item.childVms.length > 0) {
        item.childVms.forEach((i) => {
          count += this.getCount(i)
        })
      }
      return count
    },
    getSelectCount(item) {
      var count = 0
      if (item.permissionVMS && item.permissionVMS.length > 0) {
        item.permissionVMS.forEach((j) => {
          if (this.checkedKeys.findIndex((i) => {
            return i === `permission-${j.id}`
          }) !== -1 || this.disabledCheckedKeys.findIndex((i) => {
            return i === `permission-${j.id}`
          }) !== -1) {
            count = count + 1
          }
        })
      }
      if (item.childVms && item.childVms.length > 0) {
        item.childVms.forEach((i) => {
          count += this.getSelectCount(i)
        })
      }
      return count
    },
    getDisSelectCount(item) {
      var count = 0
      if (item.permissionVMS && item.permissionVMS.length > 0) {
        item.permissionVMS.forEach((j) => {
          if (this.disabledCheckedKeys.findIndex((i) => {
            return i === `permission-${j.id}`
          }) !== -1) {
            count = count + 1
          }
        })
      }
      if (item.childVms && item.childVms.length > 0) {
        item.childVms.forEach((i) => {
          count += this.getDisSelectCount(i)
        })
      }
      return count
    },
    renderSubTreeNode(item) {
      this.expandeKeys.push(`permissiongroup-${item.id}`)
      var count = this.getCount(item)
      var selectCount = this.getSelectCount(item)
      var disSelectCount = this.getDisSelectCount(item)
      return <Tree.TreeNode class={`tn ${disSelectCount === count ? 'disableCheck' : ''}`} title={
        <div>{item.name}{`（${selectCount}/${count}）`}</div>}
        key={`permissiongroup-${item.id}`}>
        {
          item.childVms && item.childVms.length > 0 ? this.renderTree(item.childVms) : ''
        }
      </Tree.TreeNode>
    },
    renderTreeNode(data) {
      this.expandeKeys.push(`permissiongroup-${data.id}`)
      var selectCount = this.getSelectCount(data)
      var disSelectCount = this.getDisSelectCount(data)
      return (
        <Tree.TreeNode
          class={`tn ${disSelectCount === data.permissionVMS.length ? 'disableCheck' : ''}`}
          key={`permissiongroup-${data.id}`}
          title={
            <div>{data.name}{data.permissionVMS && data.permissionVMS.length > 0 ? `（${selectCount}/${data.permissionVMS.length}）` : ''}</div>} >
          {
            data.permissionVMS && data.permissionVMS.length > 0 ? this.renderPermissionTreeNode(data.permissionVMS) : ''
          }
        </Tree.TreeNode>
      )
    },
    renderPermissionTreeNode(data) {
      return data.map((item) => {
        this.expandeKeys.push(`permission-${item.id}`)
        return <Tree.TreeNode
          class={`tnp ${this.disabledKeys.findIndex((key) => {
            return key === item.id
          }) > -1 ? 'disableCheck' : ''}`}
          key={`permission-${item.id}`} title={
            <div>{item.name}</div>} >
        </Tree.TreeNode>
      })
    },
    handelTreeCheck(checkedKeys, e) {
      this.checkedKeys = checkedKeys
      this.disabledKeys.forEach((item) => {
        this.checkedKeys.push(`permission-${item}`)
      })
      this.checkedKeys = Array.from(new Set(this.checkedKeys))
      // 过滤数据
      var resultList = this.checkedKeys.filter((item) => {
        return item.split('-')[0] === 'permission'
      }) || []
      this.$emit('change', resultList.map((item) => {
        return item.split('-')[1]
      }).filter((i) => {
        return this.disabledKeys.findIndex(j => {
          return `${j}` === `${i}`
        }) === -1
      }))
      this.treeNodeList = this.renderTree(this.allPermissionTree)
    }
  },

  render() {
    return (
      <div class='PermissionTree'>
        <Tree
          props={this.baseProps}
          selectable={false}
          onCheck={this.handelTreeCheck.bind(this)}
          checkable={true}
          expandedKeys={this.expandeKeys}
          checkedKeys={[...this.checkedKeys, ...this.disabledCheckedKeys]}
          defaultCheckedKeys={this.checkedKeys}
        >
          {this.treeNodeList}
        </Tree>
        <div class='btnBroup'>
        </div>
      </div>
    )
  }
}
