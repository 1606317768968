import './index.less'
import { Divider, Form, Button, Input } from 'ant-design-vue'
export default {
  name: 'FormRoleEdit',
  props: Object.assign({
    formData: {}
  }),
  created() {
    // 实例创建完成后被立即调用
    console.info('form created')
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
      console.info('form mounted')
    })
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'FormRoleEdit' })
    }
  },
  methods: {
    handleCancel() {
      // 取消提交事件发出
      this.$emit('cancel')
      // 重置form内容
      this.form.resetFields()
    },
    handleSubmit(e) {
      // 表单提交事件发出
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.code = values.name
          this.$emit('submit', values, this.form)
          // 重置form内容
          // this.form.resetFields()
        }
      })
    }
  },

  render() {
    const { formData = {} } = this.$props
    const { getFieldDecorator } = this.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 20,
          offset: 4
        }
      }
    }
    return (
      <div class='formRoleEdit'>
        <h3>
          {formData.id ? '编辑' : '创建'
          }角色</h3>
        <p>
          编辑角色会对用户造成影响请谨慎编辑
            </p>
        <Divider style={{ margin: '20px 0 20px' }} />
        <Form form={this.form}
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          onSubmit={this.handleSubmit} layout='horizontal'>
          <Form.Item label="角色名称" >
            {getFieldDecorator('name', {
              initialValue: formData.name || '',
              rules: [
                {
                  required: true,
                  message: '角色名称不可为空'
                }
              ]
            })(<Input placeholder='请输入角色名称' maxLength={10} />)}
          </Form.Item>
          {/* <Form.Item label="角色编码" >
            {getFieldDecorator('code', {
              initialValue: formData.code || '',
              rules: [
                {
                  required: true,
                  message: '角色编码不可为空'
                }
              ]
            })(<Input placeholder='请输入角色编码'/>)}
          </Form.Item> */}
          <Form.Item
            labelCol={tailFormItemLayout.labelCol}
            wrapperCol={tailFormItemLayout.wrapperCol} >
            <div class='btnBroup'>
              <Button type="default" onClick={this.handleCancel}>
                取消
               </Button>
              <Button type="primary" htmlType="submit" class='btnItem'>
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
